import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { TextField, Button } from '@mui/material';
import moment from 'moment'; // For date formatting
import Layout from './components/Layout'; // Import the Layout component
import { db, auth } from './firebaseConfig'; // Ensure correct import path for Firebase config
import { doc, setDoc, deleteDoc, getDocs, collection } from 'firebase/firestore';
import './AppPage.css';


function App() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState('');
  const [sortModel, setSortModel] = useState([{ field: 'SNo', sort: 'asc' }]);
  const [customPage, setCustomPage] = useState('');
  const [favorites, setFavorites] = useState([]);

  const fetchFavorites = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const favCollectionRef = collection(db, 'users', userId, 'favoriteGeMTenders');
    const favDocs = await getDocs(favCollectionRef);
    const favData = favDocs.docs.map(doc => doc.id); // Store sanitized BidNumbers
    setFavorites(favData);
  };

  useEffect(() => {
    fetchFavorites();
  }, []);

  const fetchData = useCallback(() => {
    const params = new URLSearchParams({
      page: page + 1, // page is zero-indexed
      limit: pageSize,
      search,
      sortField: sortModel[0]?.field || 'SNo',
      sortOrder: sortModel[0]?.sort || 'asc',
    });

    const apiUrl = process.env.REACT_APP_API_URL || 'https://webapptest1-783744cf01e6.herokuapp.com';

    fetch(`${apiUrl}/api/data?${params.toString()}`)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setTotal(result.total);
      })
      .catch((error) => console.error('Error:', error));
  }, [page, pageSize, search, sortModel]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0); // Reset to the first page on page size
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0); // Reset to the first page on search change
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const handleCustomPageChange = () => {
    const parsedPage = parseInt(customPage, 10);
    const maxPage = Math.ceil(total / pageSize) - 1; // zero-indexed
    if (parsedPage >= 1 && parsedPage <= maxPage + 1) {
      setPage(parsedPage - 1); // Adjust for zero-indexing
    } else {
      alert(`Please enter a page between 1 and ${maxPage + 1}`);
    }
  };

  const sanitizeBidNumber = (bidNumber) => {
    return bidNumber.replace(/\//g, '-'); // Replace slashes with hyphens or any other character
  };

  const toggleFavorite = async (tender) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        alert('Please log in to mark favorites.');
        return;
      }

      const sanitizedBidNumber = sanitizeBidNumber(tender.BidNumber); // Sanitize BidNumber
      const favRef = doc(db, 'users', userId, 'favoriteGeMTenders', sanitizedBidNumber);
      if (favorites.includes(sanitizedBidNumber)) {
        // Unmark as favorite
        await deleteDoc(favRef);
        setFavorites(prev => prev.filter(bid => bid !== sanitizedBidNumber));
      } else {
        // Mark as favorite
        if (favorites.length >= 15) {
          alert('You can only mark up to 15 favorites.');
          return;
        }
        await setDoc(favRef, {
          bidNumber: tender.BidNumber,
          items: tender.Items,
          district: tender.District,
          bidType: tender.BidType,
          markedDate: new Date()
        });
        setFavorites(prev => [...prev, sanitizedBidNumber]);
      }
    } catch (error) {
      console.error('Error toggling favorite status:', error);
    }
  };

  const columns = [
    {
      field: 'favoriteAction',
      headerName: 'Favorite',
      width: 100,
      renderCell: (params) => (
        <button
          onClick={() => toggleFavorite(params.row)}
          style={{
            backgroundColor: favorites.includes(sanitizeBidNumber(params.row.BidNumber)) ? 'yellow' : 'lightgray',
          }}
        >
          {favorites.includes(sanitizeBidNumber(params.row.BidNumber)) ? 'Unmark' : 'Favorite'}
        </button>
      ),
    },
    { field: 'SNo', headerName: 'S No', width: 90 },
    { field: 'State', headerName: 'State', flex: 1, minWidth: 150 },
    { field: 'District', headerName: 'District', flex: 1, minWidth: 150 },
    { field: 'BidNumber', headerName: 'Bid Number', flex: 1, minWidth: 200 },
    { field: 'BidDocumentNo', headerName: 'Bid Document No', flex: 1, minWidth: 150 },
    { field: 'Items', headerName: 'Items', flex: 1, minWidth: 300 },
    { field: 'Quantity', headerName: 'Quantity', width: 100 },
    { field: 'BidType', headerName: 'Bid Type', width: 120 },
    { field: 'Ministry', headerName: 'Ministry', flex: 1, minWidth: 200 },
    { field: 'Department', headerName: 'Department', flex: 1, minWidth: 200 },
    {
      field: 'BidURL',
      headerName: 'Bid URL',
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer" style={{ wordWrap: 'break-word', display: 'block', maxWidth: '200px' }}>
          {params.value}
        </a>
      ),
    },
    {
      field: 'StartDate',
      headerName: 'Start Date',
      width: 200,
      valueGetter: (params) => {
        const dateValue = params.row?.StartDate;
        return dateValue ? moment(dateValue).format('DD-MM-YYYY hh:mm A') : '';
      },
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
    },
    {
      field: 'EndDate',
      headerName: 'End Date',
      width: 200,
      valueGetter: (params) => {
        const dateValue = params.row?.EndDate;
        return dateValue ? moment(dateValue).format('DD-MM-YYYY hh:mm A') : '';
      },
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
    },
    {
      field: 'BidDuration',
      headerName: 'Bid Duration',
      width: 180,
    },
  ];

  // Calculate total pages (1-indexed)
  const totalPages = Math.ceil(total / pageSize);
  const currentPage = page + 1;

  return (
    <Layout>
      <div className="gem-bids-container">
        <h1>GeM Bids</h1>
        <h3>GeM Bids Updated as on 23.11.2024</h3>

        <TextField
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          style={{ marginBottom: '1rem', width: '300px' }}
          helperText="Enter keywords separated by commas (e.g., chair, table, CCTV)"
        />

        <div style={{ marginBottom: '1rem' }}>
          <TextField
            label="Go to Page"
            variant="outlined"
            value={customPage}
            onChange={(e) => setCustomPage(e.target.value)}
            style={{ marginRight: '1rem', width: '150px' }}
          />
          <Button variant="contained" onClick={handleCustomPageChange}>
            Go
          </Button>
          <p>
            Page {currentPage} of {totalPages} (Showing {pageSize} entries per page)
          </p>
        </div>
        
        <div style={{ height: '80vh', width: '100%' }}>
        <DataGrid
          rows={data}
          columns={columns}
          rowCount={total}
          pagination
          paginationMode="server"
          page={page}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[25, 50, 100]}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          getRowId={(row) => row._id || row.SNo}
          autoHeight
          getRowHeight={() => 'auto'}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
      </div>
    </Layout>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default App;
